* {
  &::selection {
    background: rgba($color-primary-light, 0.45);
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: rgba($color-primary-light, 0.4);
    border-radius: 0px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($color-primary-base, 0.4);
    border-radius: 0px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-primary-base;
  }
}

html {
  font-size: $fs-base-mobile;
  @media screen and (min-width: $breakpoint-lg) {
    font-size: $fs-base-desktop;
  }
}

body {
  background-color: $color-background-base;
  font-family: $font;
  color: $color-black;
  font-weight: $fw-light;
  margin: auto;
}

a {
  color: $color-neutral-text;
  text-decoration: none;
  &:hover {
    color: $color-neutral-text;
  }
}

ul,
ol,
dl {
  margin-top: 0;
  margin-bottom: 0;
}

.container-fluid {
  max-width: 1920px;
  margin: auto;
  @media screen and (min-width: $breakpoint-md) {
    --bs-gutter-x: 3rem;
  }
}

.autoImg {
  width: 100%;
}

.color_light {
  color: $color-primary-light;
}

.us_not {
  user-select: none;
}

.bg_xl {
  @media screen and (min-width: $breakpoint-lg) {
    background-color: $color-primary-light;
    color: $color-primary-base;
    border-radius: 999px;
    display: inline-block;
    padding: 0px 10px;
    position: relative;
    left: -10px;
  }
}

.dropdown-toggle::after {
  display: none !important;
}

.special_mt {
  margin-top: 0;
  @media screen and (min-width: $breakpoint-md) {
    margin-top: 50px;
  }
}

.splide_mr {
  margin-right: -0.75rem;
  @media screen and (min-width: $breakpoint-md) {
    margin-right: -1.5rem;
  }
}

.kontakt_map {
  border-radius: 50px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 480px;
  }
}

.icon {
  width: 50px;
  height: 50px;
  transition: all 0.5s ease;
  @media screen and (min-width: $breakpoint-md) {
    &:hover {
      transform: scale(1.2);
    }
  }

  &__insta {
    width: 40px;
    height: 40px;
    @media screen and (max-width: $breakpoint-lg-max) {
      margin-top: 5px;
    }
  }
}

.form-floating {
  font-size: $fs-l;
}

.form-control {
  color: $color-primary-base;
  border: none;
  border-bottom: 1px solid $color-primary-base;
  border-radius: 0px;
  padding: 0px;

  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid $color-primary-base;
  }
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-1.25rem) translateX(0.15rem);
}

.contactForm {
  .form-control {
    background-color: transparent;
    color: $color-white;
    border-bottom: 1px solid $color-white;
  }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    color: $color-white !important;
    background-color: transparent !important;
    transform: scale(0.85) translateY(-1.25rem) translateX(0.15rem);
    &::after {
      background-color: transparent !important;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px $color-primary-base;
  }

  .form-check-label {
    display: flex;
    align-items: center;
    color: $color-white;
    gap: 0.5rem;
    font-size: $fs-s !important;

    &::before {
      // треба ще галочку
      content: "";
      display: inline-block;
      width: 0.85rem;
      height: 0.85rem;
      background-color: $color-white;
      border-color: $color-white;
      border-radius: 0.25rem;
      margin-right: 0.5rem;
      transition: all 0.5s ease;
    }

    &.green {
      font-size: $fs-base !important;
      color: $color-primary-base;
      &::before {
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid $color-primary-base;
      }
    }
  }

  .form-check-input:checked ~ .form-check-label::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24px' height='24px'%3E%3Cpath d='M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z' fill='%23AD3B37'/%3E%3C/svg%3E")
        no-repeat center center/contain,
      $color-primary-light;
  }
  .form-check-input {
    display: none;
  }
}

.tt-normal {
  text-transform: none;
}

.min-height {
  min-height: 900px;
}

.overflow-hidden {
  overflow: hidden;
}

.contentSvg {
  width: 32px;
  height: 32px;

  @media screen and (min-width: $breakpoint-md) {
    width: 42px;
    height: 42px;
  }

  @media screen and (min-width: $breakpoint-xl) {
    width: 64px;
    height: 64px;
  }

  &_min {
    width: 16px;
    height: 16px;

    @media screen and (min-width: $breakpoint-md) {
      width: 24px;
      height: 24px;
    }
  }
}

.fix_left {
  @media screen and (min-width: $breakpoint-md) {
    transform: translateX(-14px);
  }
}

.photoCard {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.875rem;
  aspect-ratio: 736/447;
  position: relative;

  &_bf {
    background-color: #00664e;
  }

  &_hc {
    background-color: #00539c;
  }

  &_gt {
    background-color: #ad3b37;
  }

  img {
    width: 70%;
    object-fit: contain;
    transition: all 0.5s ease;
    transform: scale(0.8);
  }

  &_link {
    position: absolute;
    z-index: 1;
    bottom: 1rem;
    right: 1.5rem;
    color: $color-white;
    font-size: $fs-base;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.5s ease;

    @media screen and (min-width: $breakpoint-md) {
      font-size: $fs-l;
    }
  }

  &:hover {
    img {
      transform: scale(1);
    }
    .photoCard_link {
      transform: translateX(0.5rem);
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.gap-offer {
  gap: 50px;

  @media screen and (min-width: $breakpoint-lg) {
    gap: 80px;
  }
}
